<template>
    <phone-input
        @phone="phone = $event"
        @country="country = $event"
        @phoneData="phoneData = $event"
        class="mt-1 block w-full px-0 app-phone-field"
        :arrow="false"
        defaultCountry="US"
        :allowed="[ 'US', 'CA' ]"
        :value="model?.replace('+', '')"
    />
</template>
<script setup>
import {ref, watch} from 'vue';

const phone = ref('');
const country = ref('');
const phoneData = ref('');

const model = defineModel()

watch(phoneData, (value) => {
    if (value.number) {
        model.value = value.number;
    }
});

</script>
